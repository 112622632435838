import styled from "styled-components"
import breakpoints from "./breakpoints"
import Modal from "styled-react-modal"
import { StyledButton, closeModalButtonStyles } from "./button"
import { HeaderStyles } from "./header"
import { baseGridStyles } from "./base";
import { regular24, regular16, regular14 } from "./typography";

const StyledInfoModal = Modal.styled`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: block;
    align-items: center;
    justify-content: center;
    background-color: white;
    background: #fff url('/top-line.png') repeat-x 0 -2.4rem;
    opacity: ${(props) => props.opacity};
    transition : all 0.3s ease-in-out;
    overflow: auto;
    padding: 1.6rem;
    text-align: left;

    @media screen and (min-width: ${breakpoints.sm}px) {
        width: 85vw;
        height: 80vw;
        padding: 0 2.4rem 0;
        border-top: 2.4rem solid transparent;
        border-bottom: 2.4rem solid #fff;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        width: 65vw;
        height: 80vh;
    }

    @media screen and (min-width: ${breakpoints.l}px) {
        width: 44vw;
        height: 80vh;
    }

    article {
        // margin-top: -4.8rem;
        
        @media screen and (min-width: ${breakpoints.l}px) {
            // width: 30vw;
            // margin-left: auto;
            // margin-right: auto;
        }

        p {
            margin: 1.6rem 0 0;
            text-align: left;

            &:nth-child(1) {
                padding-right: 3.4rem;
            }
        }

        ul {
            margin-left: 1.6rem;
            li {
                ${regular14}
           }
        }
    }
`
const StyledModal = Modal.styled`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: block;
    align-items: center;
    justify-content: center;
    background-color: white;
    background: #fff url('/top-line.png') repeat-x 0 0;
    opacity: ${(props) => props.opacity};
    transition : all 0.3s ease-in-out;
    overflow: auto;
    padding: 1.6rem;
    text-align: left;

    @media screen and (min-width: ${breakpoints.sm}px) {
        width: 85vw;
        height: 80vw;
        padding: 2.4rem;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        width: 65vw;
        height: 80vh;
    }

    @media screen and (min-width: ${breakpoints.l}px) {
        width: 44vw;
        height: 80vh;
    }

    & h2:first-of-type{
        // margin-top: -3.6rem;
        margin-bottom: 1.6rem;
        padding-right: 3rem;
    }

    div, ul, h2, h3, h4, h5 {   
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: span 12;
        }
    }
    p {
        // width: 100%;
        margin-bottom: 1.6rem;

        &:last-of-type {
            margin-bottom: 1.6rem;
        }
    }
    ul, ol {
        padding-left: 2rem;
    }
    &.treatment-booking-modal {
        .pricing-table {
            height: auto;
        }
        .pricing-table {
            ul {
                list-style-type: none;
                padding-left: 0;
                padding-bottom: 1.6rem;
                margin-bottom: 0;
                li {
                    margin-bottom: 0.8rem;
                }
            }

            a {
                margin-top: 0;
                margin-left: 0;
            }
        }
    }

    &.order-modal {
        h3 {
          margin-left: 0;  
        }

        > p {
            margin-top: 1.6rem;

            @media screen and (min-width: ${breakpoints.l}px) {
                max-width: 640px;
            }
        }

        &.program-instructions {
            padding-top: 0;
            padding-bottom: 0;
            ul {
                list-style-type: circle;
                margin-top: 0;
                li {
                    color: var(--grey-darker);
                    width: 100%;
                    ${regular16}
                    display: list-item;
                    text-align: left;
                    padding: 0;

                }
            }
        }

    }

    &.deposit-modal {

        @media screen and (min-width: ${breakpoints.sm}px) {
            width: 75vw;
            height: 70vh;
            padding: 2.4rem;
        }
    
        @media screen and (min-width: ${breakpoints.md}px) {
            width: 45vw;
            height: 70vh;
        }
    
        @media screen and (min-width: ${breakpoints.l}px) {
            width: 30vw;
            height: 60vh;
        }

        p{
            margin-top: 1.6rem;
        }

        section button {
            width: 26rem;
            @media screen and (min-width: ${breakpoints.md}px) {
                display:inline-block;
                width: 50%;
                margin: 0.8rem auto; 
            }
        }
        h2 {
            margin-top: -2rem;
        }
        h3 {
            margin: 2rem 0;
        }
    }

    &.more-info-modal {
        position: relative;
        width: 90vw;
        height: 90vh;
        margin-top: 0;
        @media screen and (min-width: ${breakpoints.sm}px) {
            margin-top: 4rem;
            width: 85vw;
            padding: 2.4rem;
        }
    
        @media screen and (min-width: ${breakpoints.md}px) {
            width: 65vw;
        }
    
        @media screen and (min-width: ${breakpoints.l}px) {
            width: 44vw;
        }
        p{
            // margin-top: 6rem;
            color: var(--black);
            text-align: left;
            @media screen and (min-width: ${breakpoints.sm}px) {
                margin-top: 2.4rem;
                margin-left: 0;
                ${regular24}
                color: var(--black);
                max-width: 100vw;     
            }
        }
        h2 {
            background: var(--light-purple-darkest);
            color: var(--white);
            margin-top: -1.6rem;
            margin-left: -1.6rem;
            margin-right: -1.6rem;
            padding: 1.6rem;
            border:3px solid #fff;
            @media screen and (min-width: ${breakpoints.sm}px) {
                padding: 2.4rem;
            }
          }

        form fieldset label span {
            display: none;
        }
        form input::placeholder {
            color: var(--grey-mid-dark);
          }
    }

    &.audio-list-modal {
        padding: 0;
        @media screen and (min-width: ${breakpoints.sm}px) {
            width: 100vw;
            height: 100vh;
        }

        ul {
            padding: 1.6rem;
            @media screen and (min-width: ${breakpoints.sm}px) {
                padding: 1.7rem calc((24 / 720) * 100vw)
            }
            @media screen and (min-width: ${breakpoints.md}px) {
                padding: 1.7rem calc((24 / 1024) * 100vw)
            }
            @media screen and (min-width: ${breakpoints.l}px) {
                padding: 2.4rem 2.8rem;
            }
            h2:first-of-type {
                margin-top: 0;
                margin-bottom: 0;
                padding-right: 0;
                z-index: 1;
            }
            li {
                cursor: pointer;
            }
        }
    }

    &.shm-program-modal {
        padding: 0;
        @media screen and (min-width: ${breakpoints.sm}px) {
            width: 100vw;
            height: 100vh;
        }
    }

    &.permission-reset-sleep-modal {
        padding: 0;
        @media screen and (min-width: ${breakpoints.sm}px) {
            width: 100vw;
            height: 100vh;
        }
        h2 {
            z-index: 1;
            margin-bottom: 0;
            padding-right: 0;

            &.week-label {
                padding-right: 0.7rem;
            }
        }
        header {
            // box-shadow: none;
        }
        ul, ol {
            padding: 1.6rem;    
        }
    }
    &.spoon-therapy-modal {
        padding: 0;
        @media screen and (min-width: ${breakpoints.sm}px) {
            width: 100vw;
            height: 100vh;
        }
        h2 {
            z-index: 1;
            margin-bottom: 0;
            padding-right: 0;

            &.week-label {
                padding-right: 0.7rem;
            }
        }
        header {
            // box-shadow: none;
        }
        ul, ol {
            padding: 1.6rem;    
        }
    }

    &.gift-modal {
        
        @media screen and (min-width: ${breakpoints.sm}px) {
            width: 80vw;
            height: 30vh;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            width: 75vw;
            height: 40vh;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            width: 30vw;
            height: 50vh;
        }

        h3 {
            margin-bottom: 1.6rem;
        }

        li button {
            margin: 0;
        }
    }

    &.sign-in-modal {
        @media screen and (min-width: ${breakpoints.sm}px) {
            width: 70vw;
            height: 55vh;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            width: 44vw;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            width: 30vw;
        }

        h3 {
            margin-bottom: 1.6rem;
            margin-top: -1.6rem;
        }

        li button {
            margin: 0;
        }
    }
`;

const StyledModalHeader = styled.header`
    ${HeaderStyles}
    ${props => props.type === 'newsletter' && 'position: absolute; top: 0; right: 0;'};
    padding: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
        padding: 0.1rem 0;
        margin: 0;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        /* padding: 0;
        margin: 0; */
    }

    div {
        ${baseGridStyles}
        padding-top: 1.2rem;
        padding-bottom: 0.8rem;

        @media screen and (min-width: ${breakpoints.sm}px) {
            padding: 3.1rem calc((24 / 720) * 100vw) 1.6rem;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            padding: 3.2rem calc((24 / 1024) * 100vw) 1rem;
        }

        @media screen and (min-width: ${breakpoints.l}px) {
            width: 100%;
            max-width: 130.4rem;
            margin: 0 auto;
            padding: 2.7rem 0 2.7rem;
        }
        
        
    }

    svg {
        margin: 0rem;
        grid-column: span 2;
    }

    button{
        grid-column: span 1;
        margin-bottom: 0;
    }
`

const StyledModalCloseButton = styled(StyledButton)`
    ${closeModalButtonStyles}
    top: 0;
    margin-top: 0;
`

const StyledNewsletterModal = Modal.styled`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: block;
    align-items: center;
    justify-content: center;
    opacity: ${(props) => props.opacity};
    transition : all 0.3s ease-in-out;
    overflow: auto;
    padding: 1.6rem;
    text-align: left;

    @media screen and (min-width: ${breakpoints.sm}px) {
        width: 85vw;
        height: 80vw;
        padding: 2.4rem;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        width: 65vw;
        height: 80vh;
    }

    @media screen and (min-width: ${breakpoints.l}px) {
        width: 44vw;
        height: 80vh;
    }
    `

const StyledModalContent = styled.article`
    p {
        margin: 1.6rem 0 0;
    }
`

export {StyledModal, StyledModalHeader, StyledModalContent, StyledModalCloseButton, StyledNewsletterModal, StyledInfoModal}